.footer {
  padding: 30px 20px;
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    text-align: center;
    color: #1c1c1c;
  }
}