.breadcrumbs {
  list-style: none;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #637282;
  a {
    text-decoration: none;
    color: #697882;
    &:hover {
      color: #b43435;
    }
  }
}
