html,
body {
  height: 100%;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  color: #1c1c1c;
  font-family: 'Avenir', 'Open Sans', sans-serif;
}

#root {
  height: 100%;
}

a {
  color: #b43435;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.datepicker-input {
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  min-height: 38px;
  padding: 0 10px;
  &:focus {
    outline: none;
    border: 1px solid #ddd;
  }
}

.basic-select {
  line-height: 1.3;
}

