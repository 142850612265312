.dropdown {
  font-size: 14px;
  &_active {
    background-color: #ececec;
  }

  &__toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 20px;
    &:hover {
      background-color: #ececec;
    }
  }
  &__label {
    flex: 1;
    padding-top: 4px;
    font-weight: 500;
  }
  &__arrow {
    width: 30px;
  }
  &__navigation {
    height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transition: transform 0.5s ease;
    transform-origin: top;

    &__wrapper {
      border-top: 1px dashed #fff;
      padding: 10px 0;
    }
    &__item a {
      display: block;
      padding: 10px 20px 10px 30px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #1c1c1c;
      &:hover {
        text-decoration: none;
        color: #b43435;
        background-color: darken(#ececec, 3);
      }
      &.active {
        color: #b43435;
      }
    }
  }
  &_active &__navigation {
    height: auto;
    transform: scaleY(1);
  }
}
