.app-container {
  display: flex;
  flex-direction: row;
  min-width: 1200px;
  height: 100%;
}

.content {
  flex: 1;
  margin: 0;
  padding: 0;
  height: 100%;
}

.content-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}