.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__container {
    position: relative;
    max-width: 320px;
    padding: 15px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  &__header {
    max-width: 290px;
    width: 100%;
  }
  &__logo {
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  &__title {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    color: #757575;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  &__input {
    background-color: #f8f8f8;
    height: 43px;
    color: #8290a3;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    width: 100%;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
    padding: 6px 12px;
    display: block;
  }
  &__input:focus {
    outline: none;
    border: 1px solid #c3ccda;
  }
  &__error {
    text-align: center;
    color: #900;
    font-size: 12px;
    height: 40px;
    margin: 0;
    padding: 10px 0 0;
  }
}

