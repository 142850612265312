.form-input {
  &__select {
    padding: 5px 0 0 0;
    width: 200px;
  }
  &_inline {
    flex: 1;
    padding: 0 0 0 20px;
  }
  &_inline &__label {
    width: auto;
  }
}
