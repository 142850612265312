.tActions {
  display: inline-block;
  position: relative;
  padding: 1px 0 0 0;
  width: 34px;
  &__toggle {
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 8px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -khtml-border-radius: 4px;
    cursor: pointer;
    span {
      display: block;
      float: left;
      margin: 0 0 0 3px;
      padding: 0;
      width: 4px;
      height: 4px;
      background: #515151;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      -khtml-border-radius: 2px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &__content {
    display: none;
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 0;
    text-align: left;
    background: #fff;
    border: 1px solid #e7e7e7;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -khtml-border-radius: 4px;
    a {
      display: block;
      padding: 10px 15px;
      white-space: nowrap;
      &:hover {
        text-decoration: none;
        background: #f9f9f9;
      }
    }
  }
  &:hover .tActions__toggle,
  &__show .tActions__toggle {
    background: #e7e7e7;
    span {
      background: #515151;
    }
  }
  &__show .tActions__content {
    display: block;
  }
}