.button {
  background: #fbb332;
  font-weight: 600;
  width: 100%;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
  &:hover {
    background: darken(#fbb332, 10%);
  }
  &:disabled {
    background: #cecece;
    cursor: not-allowed;
  }
}
.button__small {
  @extend .button;
  padding: 5px 10px;
  text-transform: unset;
  width: auto;
}
