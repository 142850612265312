.form {
  flex: 1;
  overflow: auto;
  form {
    padding: 40px 50px 80px 50px;
  }
  .form-input {
    padding: 0 0 30px 0;
    &__label {
      display: block;
      padding: 0 0 5px 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      color: #637282;
    }
  }
  .form-item-action {
    padding: 20px 0 0 0;
  }
}