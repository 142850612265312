.table {
  display: flex;
  flex-direction: column;
  flex: 1;
  &__wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }
  table {
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  th {
    padding: 12px 15px 10px 15px;
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
    text-align: left;
    color: #637282;
    border: 0;
    border-bottom: 1px solid #e7e7e7;
    &:first-child {
      padding-left: 40px;
      width: 90px;
    }
    &:last-child {
      padding-right: 40px;
      width: 100px;
      text-align: right;
    }
  }
  td {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 15px 10px 15px;
    border: 0;
    border-bottom: 1px solid #e7e7e7;
    &:first-child {
      padding-left: 40px;
      width: 90px;
    }
    &:last-child {
      padding-right: 40px;
      width: 100px;
      text-align: right;
    }
  }
  &__odd td {
    background: #f4f4f4;
  }
  &__top {
    display: flex;
    flex-direction: row;
    padding: 0 20px 10px 20px;
    height: 42px;
    background-color: #ffffff;
  }
  &__info {
    flex: 1;
    margin: 0;
    padding: 10px 0 0;
    font-size: 14px;
    text-align: left;
  }
  &__pagination {
    flex: 1;
    &__inner {
      float: right;
    }
  }
  span.table__prev,
  span.table__next {
    color: #9ca2b1;
  }
  &__prev,
  &__next {
    float: left;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    border-color: #e7e7e7;
    border-style: solid;
    border-width: 1px 1px 1px 0;
  }
  &__prev {
    border-left: 1px solid #e7e7e7;
    border-radius: 5px 0 0 5px;
  }
  &__next {
    border-radius: 0 5px 5px 0;
  }
  &__head {
    background-color: #ffffff;
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    table {
      border-top: none;
    }
  }
}