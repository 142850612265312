.main-navigation {
  &__item a {
    display: block;
    padding: 10px 10px 10px 20px;
    font-size: 14px;
    line-height: 18px;
    color: #1c1c1c;
    font-weight: 500;
    &:hover {
      text-decoration: none;
      color: #b43435;
      background-color: #ececec;
    }
    &.active {
      color: #b43435;
    }
  }
}
