.form-input {
  &__input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #ccc;
    &:focus {
      outline: none;
    }
  }
}
