.sidebar {
  width: 250px;
  background: #f6f6f6;
  padding: 20px 0;
  overflow-y: auto;
  &__logo {
    margin: 0;
    padding: 0 20px 40px 20px;
  }
  &__logo-link {
    display: block;
  }
  &__logo-image {
    width: 120px;
    display: block;
  }
  &__separator {
    height: 1px;
    margin: 10px 0;
    background: #eee;
  }
}
