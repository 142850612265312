.privacy-policy {
  &__container {
    padding: 16px;
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
    text-align: justify;
  }
  &__footer {
    border-top: 1px solid #eee;
    padding: 0 0 15px;
  }
}
