.page-header {
  display: flex;
  flex-direction: row;
  padding: 18px 20px 0 20px;
  height: 90px;
  background-color: #ffffff;
  &__title {
    display: inline-block;
    margin: 0 0 5px 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    color: #b43435;
  }
  &__title-wrapper {
    flex: 1;
  }
  &__action {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  &_back &__title-wrapper {
    padding-left: 30px;
  }
  &_back &__title {
    margin-left: -30px;
    padding-left: 30px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: -9px -2px;
    background-size: 30px 30px;
    background-image: url('./assets/arrow@2x.png');
    &:hover {
      text-decoration: none;
      background-image: url('./assets/arrow-red@2x.png');
    }
  }
}

.content-form .page-header {
  border-bottom: 1px solid #e7e7e7;
}